import React, { useContext } from "react";
import {
  Playground,
  getSettings,
  setSettingsString,
  SchemaReload,
  store,
} from "graphql-playground-react";

import "./graphql.css";
import { AuthContext } from "../../Login";

const GraphQL = (props) => {
  const profile = useContext(AuthContext);

  const tabs = [
    {
      query: "",
      endpoint: process.env.REACT_APP_BASE_URL,
      responses: ["{}"],
      headers: { Authorization: profile?.tkn },
    },
  ];

  const settings = {
    "editor.cursorShape": "line",
    "editor.fontFamily":
      "Source Code Pro', 'Consolas', 'Inconsolata', 'Droid Sans Mono', 'Monaco', monospace",
    "editor.fontSize": 14,
    "editor.reuseHeaders": true,
    "editor.theme": "light",
    "general.betaUpdates": false,
    "prettier.printWidth": 80,
    "prettier.tabWidth": 2,
    "prettier.useTabs": false,
    "request.credentials": "omit",
    "schema.polling.enable": false,
    "schema.polling.endpointFilter": "*localhost*",
    "schema.polling.interval": 20000,
    "schema.disableComments": false,
    "tracing.hideTracingResponse": true,
    "tracing.tracingSupported": true,
  };

  return (
    <div className="graphqlContainer">
      <Playground
        endpoint={process.env.REACT_APP_BASE_URL}
        tabs={tabs}
        settings={settings}
      />
    </div>
  );
};

export default GraphQL;
