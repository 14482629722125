import React from "react";
import { RedocStandalone } from "redoc";
import "./docs.css";

class Docs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      spec_url: "/swagger.yml",
      success: false,
    };
  }

  render() {
    return (
      <div className="p-5 docsContainer">
        <RedocStandalone
          options={{ scrollYOffset: 125 }}
          specUrl="/swagger.yml"
        />
      </div>
    );
  }
  //}
}

export default Docs;
