const theme = {
  name: "ABx Login Theme",
  tokens: {
    colors: {
      background: {
        primary: {
          // value: "#0E5A7B",
          value: "#FFFFFF",
        },
        secondary: {
          // value: "#FF008E",
          value: "#FFFFFF",
        },
      },
      font: {
        interactive: {
          value: "#0E5A7B",
        },
      },
    },
    components: {
      field: {
        label: {
          color: {
            value: "#0E5A7B",
          },
        },
      },
      fieldcontrol: {
        border: {
          color: {
            value: "#DAEAF6",
          },
          width: {
            value: "1px",
          },
        },
      },
      button: {
        border: {
          color: {
            value: "#DAEAF6",
          },
          width: {
            value: "1px",
          },
        },
      },
      tabs: {
        item: {
          font: {
            weight: {
              value: "normal",
            },
          },
          _focus: {
            color: {
              value: "#0E5A7B",
            },
          },
          _hover: {
            color: {
              value: "#FF008E",
            },
          },
          _active: {
            color: {
              value: "#0E5A7B",
            },
          },
        },
      },
      authenticator: {
        router: {
          boxShadow: {
            value: "0px",
          },
          borderWidth: {
            value: "0px",
          },
        },
      },
    },
  },
};

export default theme;
