import { useTheme, useAuthenticator } from "@aws-amplify/ui-react";
import BlueLogo from "./img/ABx Logo Blue.png";

const components = {
  Header() {
    const { tokens } = useTheme();

    return <div className="m-8" />;
  },

  Footer() {
    const { tokens } = useTheme();

    return <div className="m-8" />;
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <div className="mt-8 mx-8">
          <img
            className="lg:hidden sm:top-28 align-top h-auto w-16 mb-4"
            src={BlueLogo}
          />
          <span className="text-abx-dark-blue text-base">
            Welcome to Alchemy Box
          </span>
          <h1 className="text-abx-dark-blue text-3xl font-semibold">Sign In</h1>
        </div>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <div className="flex flex-row-reverse w-full pr-8 pb-8">
          <button
            onClick={toResetPassword}
            size="small"
            variation="link"
            className="text-abx-dark-blue hover:text-abx-pink font-normal text-sm w-auto"
          >
            Forgotten your password?
          </button>
        </div>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      const { toSignIn } = useAuthenticator();
      return (
        <div className="mt-8 mx-8">
          <img
            className="lg:hidden sm:top-28 align-top h-auto w-16 mb-4"
            src={BlueLogo}
          />
          <span className="text-abx-dark-blue text-base">
            Welcome to Alchemy Box
          </span>
          <h1 className="text-abx-dark-blue text-3xl font-semibold">
            Register
          </h1>
          {/*<div textAlign="center">
            <button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </button>
      </div> */}
        </div>
      );
    },
    Footer() {
      return (
        <div className="flex flex-col  pb-8">
          <span className="text-abx-dark-blue px-8">
            By registering you agree to the{" "}
            <a
              href="https://alchemybox.io/terms/"
              target="_blank"
              className="text-abx-pink hover:text-abx-blue"
            >
              terms and conditions
            </a>{" "}
            and{" "}
            <a
              href="https://alchemybox.io/privacy/"
              target="_blank"
              className="text-abx-pink hover:text-abx-blue"
            >
              privacy policy
            </a>
          </span>
        </div>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <div className="">
          <img
            className="lg:hidden sm:top-28 align-top h-auto w-16 mb-4"
            src={BlueLogo}
          />
          <span className="text-abx-dark-blue text-base">
            Welcome to Alchemy Box
          </span>
          <h1 className="text-abx-dark-blue text-3xl font-semibold mb-8">
            Confirm your email address
          </h1>
          <span className="text-abx-dark-blue text-base">
            Please enter the code you recieved in your email and your new
            password, if you didn't recieve a code check that it is not in a
            spam folder.
          </span>
        </div>
      );
    },
    Footer() {
      return <span></span>;
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <div className="">
          <img
            className="lg:hidden sm:top-28 align-top h-auto w-16 mb-4"
            src={BlueLogo}
          />
          <span className="text-abx-dark-blue text-base">
            Welcome to Alchemy Box
          </span>
          <h1 className="text-abx-dark-blue text-3xl font-semibold mb-8">
            Setup TOTP
          </h1>
          <span className="text-abx-dark-blue text-base">
            Please enter the code you recieved in your email and your new
            password, if you didn't recieve a code check that it is not in a
            spam folder.
          </span>
        </div>
      );
    },
    Footer() {
      return <span></span>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <div className="">
          <img
            className="lg:hidden sm:top-28 align-top h-auto w-16 mb-4"
            src={BlueLogo}
          />
          <span className="text-abx-dark-blue text-base">
            Welcome to Alchemy Box
          </span>
          <h1 className="text-abx-dark-blue text-3xl font-semibold mb-8">
            Confirm your sign
          </h1>
          <span className="text-abx-dark-blue text-base">
            Please enter the code you recieved in your email and your new
            password, if you didn't recieve a code check that it is not in a
            spam folder.
          </span>
        </div>
      );
    },
    Footer() {
      return <span></span>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <div className="">
          <img
            className="lg:hidden sm:top-28 align-top h-auto w-16 mb-4"
            src={BlueLogo}
          />
          <span className="text-abx-dark-blue text-base">
            Welcome to Alchemy Box
          </span>
          <h1 className="text-abx-dark-blue text-3xl font-semibold mb-8">
            Forgotten Password?
          </h1>
          <span className="text-abx-dark-blue text-base">
            If you've forgotten your password, no need to worry, just enter your
            email address below and we'll set you a reset code.
          </span>
        </div>
      );
    },
    Footer() {
      return <span></span>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <div className="">
          <img
            className="lg:hidden sm:top-28 align-top h-auto w-16 mb-4"
            src={BlueLogo}
          />
          <span className="text-abx-dark-blue text-base">
            Welcome to Alchemy Box
          </span>
          <h1 className="text-abx-dark-blue text-3xl font-semibold mb-8">
            Reset your password
          </h1>
          <span className="text-abx-dark-blue text-base">
            Please enter the code you recieved in your email and your new
            password, if you didn't recieve a code check that it is not in a
            spam folder.
          </span>
        </div>
      );
    },
    Footer() {
      return <span></span>;
    },
  },
};

export default components;
