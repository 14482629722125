import React, { useContext } from "react";
import Clipboard from "react-clipboard.js";
import ReactTooltip from "react-tooltip";
import "./check.css";
import { AuthContext } from "../../Login";

const Check = () => {
  const profile = useContext(AuthContext);

  return (
    <div className="bg-abx-docs-bg h-screen p-8">
      <ReactTooltip
        event="click"
        afterShow={() => {
          setTimeout(ReactTooltip.hide, 1000);
        }}
      />
      <center>
        <br />

        <span className="text-abx-dark-blue mb-4">ID Token</span>
        <br />
        <textarea
          id="bar"
          cols="20"
          rows="1"
          className="p-4 text-abx-dark-blue"
          value={profile?.tkn}
        />
        <br />
        <Clipboard
          data-clipboard-text={profile?.tkn}
          data-tip="Copied!"
          className="bg-abx-light-blue text-abx-blue p-3 rounded-md my-4"
        >
          Copy to Clipboard
        </Clipboard>

        <br />
        <br />
      </center>
    </div>
  );
};

export default Check;
