const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      label: "Email",
      placeholder: "Enter your email",
      order: 1,
    },
    password: {
      labelHidden: false,
      label: "Password",
      placeholder: "Enter your Password:",
      isRequired: true,
      order: 2,
    },
  },
  signUp: {
    email: {
      labelHidden: false,
      label: "Email",
      placeholder: "Enter your email:",
      isRequired: true,
      order: 1,
    },
    password: {
      labelHidden: false,
      label: "Password",
      placeholder: "Enter your Password:",
      isRequired: true,
      order: 2,
    },
    confirm_password: {
      labelHidden: false,
      label: "Confirm Password",
      isRequired: true,
      order: 3,
    },
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: "Enter your Password:",
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      label: "Email",
      placeholder: "Enter your email:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: "Enter your Confirmation Code:",
      label: "Code",
      isRequired: false,
    },
    confirm_password: {
      labelHidden: false,
      label: "Password",
      placeholder: "Enter your Password Please:",
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user",
    },
    confirmation_code: {
      labelHidden: false,
      label: "Code",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: "Code",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};

export default formFields;
