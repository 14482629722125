import React from "react";
import { Link, withRouter } from "react-router-dom";
import { AuthContext } from "../../Login";
import { useContext } from "react";
import Logo from "../../Login/img/ABx Logo.png";
import Docs from "../docs/docs";
import Check from "../check/checkKey";
import { Menu } from "@headlessui/react";
import "./NavBar.css";

function NavBar(props) {
  const profile = useContext(AuthContext);

  const ShowDocs = () => {
    return <Docs spec_url="/swagger.json" />;
  };

  const ShowCheck = () => {
    return <Check />;
  };

  return (
    <nav className="p-4 bg-abx-dark-blue h-28 flex flex-row items-center align-middle">
      <div className="flex-none mr-4">
        <img className="h-auto w-16 mb-4" src={Logo} />
      </div>
      <div className="flex-grow col-end-8 col-span-2 align-top">
        <div>
          <Menu>
            <Menu.Button className="bg-abx-light-blue hover:bg-abx-pink p-3 rounded-md w-64">
              Alchemy Box API (v0.1)
            </Menu.Button>
            <Menu.Items className="absolute mt-2 flex flex-col space-y-4 bg-abx-light-blue rounded-md w-64 z-50">
              <Menu.Item className="bg-abx-light-blue text-abx-dark-blue hover:bg-abx-pink hover:text-white w-full h-full p-3">
                {({ active }) => <Link to="/graphql">Try me out</Link>}
              </Menu.Item>
              <Menu.Item className="bg-abx-light-blue text-abx-dark-blue hover:bg-abx-pink hover:text-white w-full h-full p-3">
                {({ active }) => <Link to="/check">Get ID Token</Link>}
              </Menu.Item>
              <Menu.Item className="bg-abx-light-blue text-abx-dark-blue hover:bg-abx-pink hover:text-white w-full h-full p-3">
                {({ active }) => <Link to="/">Documentation</Link>}
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
      <button
        className="btn sign-out flex-none"
        onClick={() => {
          profile.signOut();
        }}
      >
        Sign Out
      </button>
    </nav>
  );
}

export default withRouter(NavBar);
