import { Auth } from "aws-amplify";
import currency from "./data/currency.json";
import country from "./data/country_code.json";

export default function GetServices() {
  const getLocale = () => {
    if (navigator.languages !== undefined) return navigator.languages[0];
    return navigator.language;
  };

  const checkCurrency = (ctry) => {
    try {
      return currency.find((curr) => curr.iso_country === country[ctry]);
    } catch (err) {
      return {
        name: "",
        iso_country: "",
        currency_name: "",
        currency_code: "",
        currency_symbol: "",
        suffix: null,
      };
    }
  };

  return {
    async handleSignUp(formData) {
      let { username, password, attributes } = formData;
      const res = await fetch(
        `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_KEY}`,
      );
      const out = await res.json();
      let countryDets = checkCurrency(out.country);
      // console.log("out", formData);
      const newAttributes = {
        "custom:timezone": out.timezone,
        "custom:locale": getLocale(),
        "custom:city": out.city,
        "custom:is_eu": String(countryDets.is_eu),
        "custom:country": countryDets.iso_country,
        "custom:country_name": countryDets.name,
        "custom:currency_code": countryDets.currency_code,
        "custom:currency_name": countryDets.currency_name,
        "custom:currency_symbol": countryDets.currency_symbol,
      };
      // custom email
      username = username.toLowerCase();
      attributes.email = attributes.email.toLowerCase();
      attributes = { ...attributes, ...newAttributes };
      return Auth.signUp({
        username,
        password,
        attributes,
      });
    },
  };
}
