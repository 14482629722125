import React, { createContext, useState, useEffect } from "react";
import "./index.css";
import { Amplify } from "aws-amplify";
import jwtDecode from "jwt-decode";

import {
  Authenticator,
  useTheme,
  ThemeProvider,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import components from "./loginComponents";
import formFields from "./loginFields";
import GetServices from "./loginAttributes";
import theme from "./loginTheme";
import Logo from "./img/ABx Logo.png";
import Clouds from "./img/clouds.png";

// import awsExports from "./aws-exports";

export const AuthContext = createContext();

const awsExports = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USERPOOL,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: ["aws.cognito.signin.user.admin", "email", "openid", "profile"],
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

Amplify.configure(awsExports);

const services = GetServices();

function AuthProvider({ children }) {
  const { tokens } = useTheme();
  const { authStatus, user, signOut } = useAuthenticator((context) => [
    context.authStatus,
  ]);
  const [profile, setProfile] = useState({});
  const [tkn, setTkn] = useState("");

  //const authStatus = true;
  const getProfile = async () => {
    console.log("Getting profile...");
    const tknDetail = await user.getSignInUserSession();
    const decoded = jwtDecode(tknDetail?.idToken?.jwtToken);
    setProfile(decoded);
    setTkn(tknDetail?.idToken?.jwtToken);
  };

  useEffect(() => {
    authStatus === "authenticated" && getProfile();
  }, [authStatus]);

  return (
    <div
      className={authStatus !== "authenticated" ? "bg-abx-mid-grey p-10" : ""}
    >
      <div className="flex flex-row font-poppins text-abx-pink justify-center align-middle items-center lg:h-full bg-white lg:bg-abx-dark-blue h-max min-h-screen">
        <div
          className={`grid grid-cols-1 ${
            authStatus !== "authenticated" ? "lg:grid-cols-2" : "lg:grid-cols-1"
          }`}
        >
          <div
            className={`hidden bg-abx-dark-blue h-screen ${
              authStatus !== "authenticated" && "lg:block"
            }`}
          >
            <div
              className={`hidden bg-abx-dark-blue min-h-max w-full p-12 ${
                authStatus !== "authenticated" && "lg:block"
              }`}
            >
              <img
                className="sm:top-28 align-top h-auto w-16 mb-4"
                src={Logo}
              />
              <span className="text-white text-5xl lg:text-6xl font-semibold leading-tight lg:leading-normal">
                Meet your CFO in the cloud
              </span>
              <img className="h-auto w-full mt-4 max-w-md" src={Clouds} />
            </div>
          </div>
          <div
            className={`${
              authStatus !== "authenticated" ? "w-full" : "w-screen"
            } min-h-screen h-max bg-white`}
          >
            <div className="relative">
              <ThemeProvider theme={theme}>
                <Authenticator
                  loginMechanisms={["email"]}
                  socialProviders={["facebook", "google"]}
                  formFields={formFields}
                  components={components}
                  services={services}
                >
                  {authStatus === "configuring" && <span>Loading...</span>}
                  {authStatus === "authenticated" && (
                    <AuthContext.Provider
                      value={{
                        ...profile,
                        signOut: signOut,
                        tkn: tkn,
                      }}
                    >
                      {children}
                    </AuthContext.Provider>
                  )}
                </Authenticator>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthProvider;
