import React from "react";
import "./App.css";
import Info from "./components/info/info";
import Nav from "./components/NavBar/NavBar";
import { Route, Switch } from "react-router-dom";
import Docs from "./components/docs/docs";
import Check from "./components/check/checkKey";
import GraphQL from "./components/graphql/GraphQL";
import "react-notifications/lib/notifications.css";

export default function App() {
  return (
    <div>
      <Nav />
      <Switch>
        <Route exact path="/" component={Info} />
        <Route exact path="/check" component={Check} />
        <Route path="/graphql" component={GraphQL} />
        <Route path="/info" component={Docs} />
      </Switch>
    </div>
  );
}
