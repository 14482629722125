import React from "react";
import ReactMarkdown from "react-markdown";
import input from "./info.md";
import "./info.css";

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = { info: "Loading documentation..." };
  }

  componentWillMount() {
    fetch(input)
      .then((response) => response.text())
      .then((text) => {
        this.setState({ info: text });
      });
  }

  componentWillUnmount() {
    this.setState({ info: "" });
  }

  render() {
    const { info } = this.state;
    return (
      <div className="infoContainer">
        <ReactMarkdown source={info} escapeHtml={false} />
        <button className="buttonsTop">
          <a href="#top" className="buttonLink">
            Back to Top
          </a>
        </button>
      </div>
    );
  }
}

export default Info;
