import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "graphql-playground-react";
import "./index.css";
import App from "./App";
//import Login from './components/auth/Login'
import AuthProvider from "./Login";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter } from "react-router-dom";
import dotenv from "dotenv";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production

dotenv.config();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Authenticator.Provider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Authenticator.Provider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
